import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import Wysiwyg from "components/Edit/Wysiwyg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import ContentsTabsPanel from "components/Edit/Entry/ContentsTabsPanel";
import CustomizedDatePickerMui from "components/Edit/CustomizedDatePickerMui";
import TabPanel from "components/Edit/CustomizedTabPanel";
import Box from "@mui/material/Box";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import TagsPanel from "components/Edit/Product/TagsPanel";
import SectionItemsPanel from "components/Edit/Product/SectionItemsPanel";
import CircularProgress from "@mui/material/CircularProgress";
import CustomizedSelectBool from "components/Edit/CustomizedSelectBool";
import { PRODUCT_TYPES } from "enums/productTypes";
import TextField from "@mui/material/TextField";
import CustomizedIconChoice from "components/Edit/CustomizedIconChoice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import SelectDiscountCodeBanner from "components/SelectDiscountCodeBanner";
import Divider from "@mui/material/Divider";
import CustomizedMetaTextField from "components/Edit/CustomizedMetaTextField";

const yesNoSelect = [
  {
    id: true,
    displayName: "Tak",
  },
  {
    id: false,
    displayName: "Nie",
  },
];

const laboratories = [
  { id: "GENOMED_SA", displayName: "GENOMED_SA" },
  { id: "DF_MEDICA_POLSKA_SP_J", displayName: "DF_MEDICA_POLSKA_SP_J" },
  { id: "LABORATORIUM_ANALITYKI_MEDYCZNEJ_ADLAB", displayName: "LABORATORIUM_ANALITYKI_MEDYCZNEJ_ADLAB" },
  { id: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA", displayName: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA" },
  {
    id: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA_ZYCZKOWSKIEGO_KRAKOW",
    displayName: "MEDYCZNE_LABORATORIUM_DIAGNOSTYKA_ZYCZKOWSKIEGO_KRAKOW",
  },
  { id: "CAMBRIDGE_DIAGNOSTICS", displayName: "CAMBRIDGE_DIAGNOSTICS" },
  { id: "LABORATORIUM_MASDIAG", displayName: "LABORATORIUM_MASDIAG" },
  { id: "CM_VITAIMMUN", displayName: "CM_VITAIMMUN" },
  { id: "AL_MED", displayName: "AL_MED" },
  { id: "LIFELINE_DIAG", displayName: "LIFELINE_DIAG" },
  { id: "BADAMY_GENY", displayName: "BADAMY_GENY" },
  { id: "TEST_DNA_LABORATORIUM", displayName: "TEST_DNA_LABORATORIUM" },
  { id: "INSTYTUT_GENETYKI_SADOWEJ_SP_ZOO", displayName: "INSTYTUT_GENETYKI_SADOWEJ_SP_ZOO" },
  { id: "GENXONE_SA", displayName: "GENXONE_SA" },
  { id: "ALAB", displayName: "ALAB" },
  { id: "FULGENT", displayName: "FULGENT" },
  { id: "HEALTH_LABS", displayName: "Health Labs sp. z o.o." },
  { id: "LABORATORIUM_BADAN_TOKSYKOLOGICZNYCH", displayName: "Laboratorium Badań Toksykologicznych Sp. z o.o." },
  { id: "GENMED", displayName: "GenMed Laboratorium Diagnostyki Molekularnej" },
  { id: "DIAGNOSTYKA_LABORATORIUM_JUTRZENKI", displayName: "Diagnostyka laboratorium PPM ul. Jutrzenki" },
  { id: "HELISA", displayName: "Helisa Medyczne Laboratorium Diagnostyczne" },
  { id: "CENTRUM_BADAN_DNA", displayName: "Centrum Badań DNA Sp. z o.o." },
  { id: "INSTYTUT_MIKROEKOLOGII", displayName: "Instytut Mikroekologii" },
];

const taxRates = [
  { id: "TAX_0", displayName: "ZW" },
  { id: "TAX_8", displayName: "8" },
  { id: "TAX_23", displayName: "23" },
];

const orderWorkflows = [
  { id: "NEW_LEAD", displayName: "New Lead" },
  { id: "NEW_COMMENT", displayName: "New Comment" },
  { id: "NEW_ORDER", displayName: "Nowe zamówienie - default" },
  { id: "ADB_NEW_ORDER", displayName: "Nowe Zamówienie - Adb Lab" },
  {
    id: "LABORATORIUM_BADAN_TOKSYLOGICZNYCH_NEW_ORDER",
    displayName: "Nowe Zamówienie - Laboratorium Badan Toksylogicznych",
  },
  { id: "NEW_ORDER_WITHOUT_LAB", displayName: "Nowe Zamówienie - Bez laboratorium" },
  { id: "NEW_DIAGNOSTYKA_WITHOUT_LAB", displayName: "Nowe Zamówienie - Diagnostyka, Bez laboratorium" },
  { id: "FOOD_WITHOUT_LAB", displayName: "Nowe Zamówienie - Cambridge Lab" },
  { id: "FOODSCREEN_WITHOUT_LAB", displayName: "Nowe Zamówienie - Vitaimmun bez Lab" },
  { id: "LIFELINE_DIAG_NEW_ORDER", displayName: "Nowe Zamówienie - Lifeline Diag" },
  { id: "NEW_BADAMY_GENY_WITHOUT_LAB", displayName: "Nowe Zamówienie - Badamy Geny bez Lab" },
  { id: "NEW_TESTDNA_LABORATORIUM", displayName: "Nowe Zamówienie - TESTDNA" },
  { id: "FULGENT_GENETICS", displayName: "Nowe Zamówienie - Fulgent Genetics" },
  { id: "EMPTY_WORKFLOW", displayName: "Pusty workflow" },
];

const productVariants = [
  {
    id: "STANDARD",
    displayName: "Standardowy",
  },
  {
    id: "SUPLEMENT",
    displayName: "Suplement",
  },
  {
    id: "CUSTOM",
    displayName: "Indywidualnie wyceniany",
  },
];

const endpoint = "/product/";
const title = "shop_product_edit_label";
const moduleUrl = "/shop/products/";

const MAX_SHORT_LIST = 3;

function isSpecialProduct(id) {
  return id == 13300;
}

const ProductEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    shortName: Yup.string().nullable(),
    url: isSpecialProduct(id) ? Yup.string().nullable() : Yup.string(),
    active: Yup.bool().required(t("form-validation-required")),
    excludeFromSiteMap: Yup.bool().nullable(),
    icon: Yup.string().nullable(),
    image: Yup.string().nullable(),
    imageAltText: Yup.string().trim().nullable(),
    iconAltText: Yup.string().trim().nullable(),
    metaTitle: Yup.string().nullable(),
    metaDescription: Yup.string().nullable(),
    intro: Yup.string().nullable(),
    introList: Yup.string().nullable(),
    introBoldEdit: Yup.string().nullable(),
    introEdit: Yup.string().nullable(),
    categoryId: Yup.number().typeError(t("form-validation-required")).required(t("form-validation-required")),
    technology: Yup.string().nullable(),
    resultTime: Yup.string().nullable(),
    sectionTitle: Yup.string().nullable(),
    productType: Yup.string().trim().nullable(),
    productVariant: Yup.string().trim().nullable(),
    laboratory: Yup.string().trim().nullable(),
    orderWorkflow: Yup.string().trim().nullable(),
    newProductBadge: Yup.bool().typeError(t("form-validation-required")),
    communicationWorkflow: Yup.number().nullable(true),
    activeStartDate: Yup.string().trim().nullable(),
    activeEndDate: Yup.string().trim().nullable(),
    needPackage: Yup.boolean().typeError(t("form-validation-required")),
    codes: Yup.string().nullable(),
    retailPrice: Yup.string().required(t("form-validation-required")),
    salePrice: Yup.string().nullable(),
    tax: Yup.string().required(t("form-validation-required")),
    temporarilyUnavailable: Yup.bool().typeError(t("form-validation-required")),
    productLimit: Yup.number().typeError(t("form-validation-number")).integer(t("form-validation-number")),
    searchWeight: Yup.string().nullable(),
    promotionDiscountCodeId: Yup.string().trim().nullable(),
    promotionDiscountCodeItemId: Yup.string()
      .when("promotionDiscountCodeId", {
        is: (p) => !p || p === "",
        then: Yup.string().trim().nullable(),
      })
      .when("promotionDiscountCodeId", {
        is: (p) => p && p !== "",
        then: Yup.string().trim().required(t("form-validation-required")),
      }),
    feature: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
    upSells: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    crossSells: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    inPackages: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        productId: Yup.string().nullable(),
      }),
    ),
    searchTags: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
    ),
    negativeSearchTags: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }),
    ),
    tabs: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
        content: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
  });

  const defaultValue = {
    name: "",
    shortName: "",
    url: "",
    active: true,
    excludeFromSiteMap: false,
    icon: "",
    image: "",
    imageAltText: "",
    iconAltText: "",
    metaTitle: "",
    metaDescription: "",
    intro: "",
    introList: "",
    introBoldEdit: "",
    introEdit: "",
    categoryId: "",
    technology: "",
    resultTime: "",
    sectionTitle: "",
    productType: "",
    productVariant: "",
    laboratory: "",
    orderWorkflow: "",
    newProductBadge: null,
    communicationWorkflow: null,
    activeStartDate: "",
    activeEndDate: "",
    needPackage: null,
    codes: "",
    retailPrice: "",
    salePrice: "",
    tax: "",
    temporarilyUnavailable: null,
    productLimit: 0,
    searchWeight: "",
    promotionDiscountCodeId: "",
    promotionDiscountCodeItemId: "",
    feature: [],
    upSells: [],
    crossSells: [],
    inPackages: [],
    searchTags: [],
    negativeSearchTags: [],
    tabs: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={true}
        defaultValue={defaultValue}
        deleteButtonTitle={"Usuń Produkt"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { control, formState, setValue, getValues } = useFormContext();
  const { t } = useTranslation("common");

  const [activeTabLinked, setActiveTabLinked] = useState(0);
  const [allActiveCategory, setAllActiveCategory] = useState([]);
  const [allActiveGroup, setAllActiveGroup] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [accordionWithCategoryOpen, setAccordionWithCategoryOpen] = useState(false);

  const [openedDialogUpSell, setOpenedDialogUpSell] = useState(false);
  const [openedDialogCrossSell, setOpenedDialogCrossSell] = useState(false);
  const [openedDialogInPackages, setOpenedDialogInPackages] = useState(false);

  const [isMoreProductsUpSell, setIsMoreProductsUpSell] = useState(false);
  const [isMoreProductsCrossSell, setIsMoreProductsCrossSell] = useState(false);
  const [isMoreInPackages, setIsMoreInPackages] = useState(false);

  const [isAllProductReceived, setIsAllProductRecived] = useState(false);

  const upSellList = useWatch({
    control,
    name: `upSells`,
  });
  const crossSellList = useWatch({
    control,
    name: `crossSells`,
  });

  const inPackagesList = useWatch({
    control,
    name: `inPackages`,
  });

  useEffect(() => {
    backend.get("/category/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allCategory = res.data;
          const activeCategory = allCategory.filter((item) => {
            return item.active;
          });
          const category = getValues("categoryId");

          if (
            !category ||
            activeCategory.find(function (element) {
              return element.id == category;
            })
          ) {
            setAllActiveCategory(activeCategory);
          } else if (
            allCategory.find(function (element) {
              return element.id == category;
            })
          ) {
            setAllActiveCategory([
              ...activeCategory,
              ...allCategory.filter((item) => {
                return item.id == category;
              }),
            ]);
          } else {
            setAllActiveCategory([...activeCategory, { id: category, displayName: "Item removed?", active: false }]);
          }
        }
      }
    });
    return () => {
      setAllActiveCategory([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/communication-group/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          const allGroup = res.data;
          const activeGroup = allGroup.filter((item) => {
            return item.active;
          });
          const group = getValues("communicationWorkflow");

          if (
            !group ||
            activeGroup.find(function (element) {
              return element.id == group;
            })
          ) {
            setAllActiveGroup(activeGroup);
          } else if (
            allGroup.find(function (element) {
              return element.id == group;
            })
          ) {
            setAllActiveGroup([
              ...activeGroup,
              ...allGroup.filter((item) => {
                return item.id == group;
              }),
            ]);
          } else {
            setAllActiveGroup([...activeGroup, { id: group, displayName: "Item removed?", active: false }]);
          }
        }
      }
    });
    return () => {
      setAllActiveGroup([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
          setAllProducts(res.data);
        }
        setIsAllProductRecived(true);
      }
    });
    return () => {
      setAllProducts([]);
    };
  }, []);

  const handleChangeLinked = (event, newValue) => {
    setActiveTabLinked(newValue);
  };

  useEffect(() => {
    if (upSellList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of upSellList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("upSells", newFields);
    }

    if (crossSellList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of crossSellList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("crossSells", newFields);
    }

    if (inPackagesList?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of inPackagesList) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "Item removed?" });
        }
      }

      setValue("inPackages", newFields);
    }
  }, [current, allProducts]);

  useEffect(() => {
    if (formState?.errors["categoryId"]) {
      setAccordionWithCategoryOpen(true);
    }
  }, [formState.errors]);

  const moveItem = (dragIndex, hoverIndex, name) => {
    const fields = getValues(name);
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue(name, newFields);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={1}>
            <InputLabel htmlFor={"id"}>Id</InputLabel>
            <TextField variant="outlined" size="small" fullWidth={true} disabled={true} value={id} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa skrócona"} name={"shortName"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"URL"} name={"url"} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch
              name={"active"}
              topLabel={t("cms_edit_page_state")}
              rightLabel={t("cms_edit_page_online")}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomizedSwitch name={"excludeFromSiteMap"} topLabel={"Usuń z sitemapy"} rightLabel={"Tak"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedImagePicker
              nameUrl={"image"}
              nameAlt={"imageAltText"}
              label={"Obrazek - strona główna"}
              placeholder={"Wybrany obrazek:"}
            />
            <CustomizedTextField label={"Obrazek strona główna - tekst alternatywny"} name={"imageAltText"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedIconChoice name={"icon"} label={"Ikona"} />
            <CustomizedTextField label={"Ikona - tekst alternatywny"} name={"iconAltText"} />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              {t("cms_edit_seo_data")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <CustomizedMetaTextField label={"Meta title"} name={"metaTitle"} type={"TITLE"} />
            <br />
            <CustomizedMetaTextField label={"Meta description"} name={"metaDescription"} type={"DESCRIPTION"} />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Zajawka
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container item xs={12} spacing={5}>
              <Grid item xs={12}>
                <Wysiwyg label={"Intro"} name={"intro"} control={control} errors={formState?.errors} />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={"Intro lista"} name={"introList"} />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={"Intro bold edycja"} name={"introBoldEdit"} />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={"Intro edycja"} name={"introEdit"} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square expanded={accordionWithCategoryOpen}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
            onClick={() => setAccordionWithCategoryOpen((prev) => !prev)}
          >
            <Typography variant="h4" component={"h4"}>
              Ustawienia produktu
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Kategoria głowna"} name={"categoryId"} items={allActiveCategory} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={"Technologia badania"} name={"technology"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={"Czas oczekiwania na wynik"} name={"resultTime"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={"Tytuł sekcji"} name={"sectionTitle"} />
              </Grid>
              <Grid item xs={12} sx={{ mb: 5 }}>
                <SectionItemsPanel label={"Elementy sekcji"} name={"features"} extendedRow={false} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Typ produktu"} name={"productType"} items={PRODUCT_TYPES} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Wariant produktu"} name={"productVariant"} items={productVariants} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Laboratorium"} name={"laboratory"} items={laboratories} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Proces obsługi zamówienia"} name={"orderWorkflow"} items={orderWorkflows} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelectBool label={"Wyświetlić etykietę “Nowy produkt”?"} name={"newProductBadge"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Grupa komunikatów"} name={"communicationWorkflow"} items={allActiveGroup} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedDatePickerMui label={"Produkt aktywny od"} name="activeStartDate" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedDatePickerMui label={"Produkt aktywny do"} name="activeEndDate" />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Cena
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <CustomizedSelectBool label={"Doliczać cenę za przesyłkę?"} name={"needPackage"} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomizedTextField label={"Kod badania"} name={"codes"} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomizedTextField disabled label={"Atrybuty"} name={"attributes"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={"Cana sprzedaży"} name={"retailPrice"} type={"number"} step={"0.01"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField label={"Cena przed"} name={"salePrice"} type={"number"} step={"0.01"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelect label={"Stawka podatku"} name={"tax"} items={taxRates} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedSelectBool
                  label={"Produkt tymczasowo niedostępny"}
                  name={"temporarilyUnavailable"}
                  items={yesNoSelect}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedTextField
                  label={"Limit (0 - infinity)"}
                  name={"productLimit"}
                  type={"number"}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <SelectDiscountCodeBanner />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Tabs onChange={handleChangeLinked} value={activeTabLinked} aria-label="tabSection" centered>
        <Tab label="Produkty powiązane" />
        <Tab label="Wyszukiwarka" />
      </Tabs>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <TabPanel value={activeTabLinked} index={0}>
          <Box id={"up-sell"}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant={"h5"}>Up-sell</Typography>
                {/*"upSells": [],*/}
              </Grid>
              <Grid item sx={{ textAlign: "right", mb: 2 }}>
                <Button variant="contained" color="secondary" size="small" onClick={() => setOpenedDialogUpSell(true)}>
                  Edytuj listę
                </Button>
              </Grid>
            </Grid>
            {!isAllProductReceived ? (
              <CircularProgress size={20} />
            ) : !upSellList?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                {/*<List dense sx={{ pb: 0 }}>*/}
                {/*  {upSellList?.map(*/}
                {/*    (item, index) =>*/}
                {/*      (index < MAX_SHORT_LIST || isMoreProductsUpSell) && (*/}
                {/*        <CustomizedListItem key={item.id} primaryText={item.name} secondaryText={item.url} />*/}
                {/*      ),*/}
                {/*  )}*/}
                {/*</List>*/}
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {upSellList?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreProductsUpSell) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "upSells")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.url}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={upSellList}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreProductsUpSell}
                  setFullListOpen={setIsMoreProductsUpSell}
                />
              </>
            )}
          </Box>
          <Box id={"cross-sell"}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant={"h5"}>Cross-sell</Typography>
                {/*"crossSells": []*/}
              </Grid>
              <Grid item sx={{ textAlign: "right", mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setOpenedDialogCrossSell(true)}
                >
                  Edytuj listę
                </Button>
              </Grid>
            </Grid>
            {!isAllProductReceived ? (
              <CircularProgress size={20} />
            ) : !crossSellList?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                {/*<List dense sx={{ pb: 0 }}>*/}
                {/*  {crossSellList?.map(*/}
                {/*    (item, index) =>*/}
                {/*      (index < MAX_SHORT_LIST || isMoreProductsCrossSell) && (*/}
                {/*        <CustomizedListItem key={item.id} primaryText={item.name} secondaryText={item.url} />*/}
                {/*      ),*/}
                {/*  )}*/}
                {/*</List>*/}
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {crossSellList?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreProductsCrossSell) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "crossSells")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.url}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={crossSellList}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreProductsCrossSell}
                  setFullListOpen={setIsMoreProductsCrossSell}
                />
              </>
            )}
          </Box>
          <Box id={"in-packages-box"}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant={"h5"}>Produkt występuje w pakiecie</Typography>
              </Grid>
              <Grid item sx={{ textAlign: "right", mb: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setOpenedDialogInPackages(true)}
                >
                  Edytuj listę
                </Button>
              </Grid>
            </Grid>
            {!isAllProductReceived ? (
              <CircularProgress size={20} />
            ) : !inPackagesList?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {inPackagesList?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreInPackages) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "inPackages")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.url}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={inPackagesList}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreInPackages}
                  setFullListOpen={setIsMoreInPackages}
                />
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={activeTabLinked} index={1}>
          <Box id={"excluded"}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <CustomizedTextField label={"Waga"} name={"searchWeight"} type={"number"} step={"0.01"} />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <TagsPanel name={"negativeSearchTags"} label={"Negatywne tagi"} />
              </Grid>
              <Grid item xs={12}>
                <TagsPanel name={"searchTags"} label={"Pozytywne tagi"} />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Paper>

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Treść główna
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <ContentsTabsPanel />
          </AccordionDetails>
        </Accordion>
      </Paper>
      {isAllProductReceived && (
        <CategoryEditListDialogControled
          handleClose={() => setOpenedDialogUpSell(false)}
          open={openedDialogUpSell}
          allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
          nameFormTables={"upSells"}
          nameToDisplay={"name"}
          setFieldsToRightColumnFromAllItems={false}
        />
      )}
      {isAllProductReceived && (
        <CategoryEditListDialogControled
          handleClose={() => setOpenedDialogCrossSell(false)}
          open={openedDialogCrossSell}
          allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
          nameFormTables={"crossSells"}
          nameToDisplay={"name"}
          setFieldsToRightColumnFromAllItems={false}
        />
      )}
      {isAllProductReceived && (
        <CategoryEditListDialogControled
          handleClose={() => setOpenedDialogInPackages(false)}
          open={openedDialogInPackages}
          allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
          nameFormTables={"inPackages"}
          nameToDisplay={"name"}
          setFieldsToRightColumnFromAllItems={false}
        />
      )}
    </>
  );
};

export default ProductEdit;
